:root {
  --bs-link-color: white !important;
  --bs-link-hover-color: inherit !important;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.ttf');
}

body {
  font-display: swap;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
}

a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  text-decoration: none !important;
}

.sidebar-sticky {
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
}

.lk-focus-layout {
  height: 100% !important;
}

.lk-control-bar {
  background-color: #111111;
}
